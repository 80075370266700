var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wameed-dashboard-page-content"},[_c('page-header',{attrs:{"title":_vm.$t('profits.title'),"sub-title":'23 ' + _vm.$t('profits.subtitle')}}),_c('div',[_c('filter-header',{attrs:{"content":_vm.content},on:{"applyFilter":_vm.applyFilter,"resetFilter":_vm.resetFilter,"orderApplicants":_vm.orderApplicants}})],1),_c('section',{staticClass:"\n      wameed-dashboard-page-content_body\n      d-flex\n      flex-column\n      justify-content-between\n      overflow-hidden\n    "},[_c('div',{staticClass:"d-flex flex-wrap flex-1 px-2"},[_c('b-col',{staticClass:"px-0",attrs:{"lg":"12","md":"12"}},[_c('div',{staticClass:"w-table d-flex flex-column"},[(_vm.getAllProfits)?_c('w-tables',{attrs:{"per-page":_vm.filterData.per_page,"items":_vm.getAllProfits,"fields":_vm.fields,"status":_vm.status,"custimized-items":[
              { name: 'title' },
              { name: 'registered' },
              { name: 'action' },
              { name: 'start_date' },
              { name: 'end_date' },
              { name: 'people' },
              { name: 'amount' } ]},scopedSlots:_vm._u([{key:"title",fn:function(ref){
            var data = ref.data;
return [_c('div',{staticClass:"d-flex justify-center align-items-center"},[_c('b-avatar',{attrs:{"src":data.item.image}}),_c('span',{staticClass:"text-font-main mx-3"},[_vm._v(_vm._s(data.item.title))])],1)]}},{key:"start_date",fn:function(ref){
            var data = ref.data;
return [_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"text-font-main mx-3"},[_vm._v(_vm._s(_vm.moment(data.item.start_date).format('ll')))])])]}},{key:"end_date",fn:function(ref){
            var data = ref.data;
return [_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"text-font-main mx-3"},[_vm._v(_vm._s(_vm.moment(data.item.end_date).format('ll')))])])]}},{key:"people",fn:function(ref){
            var data = ref.data;
return [_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"text-font-main mx-3"},[_vm._v(_vm._s(data.item.people)+" "+_vm._s(_vm.$t('packages.tourist')))])])]}},{key:"amount",fn:function(ref){
            var data = ref.data;
return [_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"text-font-main mx-3"},[_vm._v(_vm._s(data.item.amount.toFixed(2))+" "+_vm._s(_vm.$t('common.SR')))])])]}},{key:"action",fn:function(ref){
            var data = ref.data;
return [_c('b-dropdown',{attrs:{"variant":"background","toggle-class":"text-decoration-none rounded-10 px-2 py-2 ","menu-class":"rounded-14","no-caret":"","dropleft":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('vertical-dots-icon',{staticClass:"mx-3 my-1"})]},proxy:true}],null,true)},[_c('b-dropdown-item',{attrs:{"link-class":"py-0min-height: 52px;"},on:{"click":function($event){return _vm.packageDetails(data.item.id)}}},[_c('span',{staticClass:"text-regular-14 text-font-secondary"},[_c('eyeon-icon',{staticClass:"mx-2"}),_vm._v(" "+_vm._s(_vm.$t('common.show_details'))+" ")],1)])],1)]}}],null,false,2641804317)}):_vm._e(),(_vm.getAllProfits && _vm.getAllProfits.length < 1)?_c('wameed-no-data',{attrs:{"icon":"nodata-icon","title":_vm.$t('packages.no_data'),"sub-title":_vm.$t('packages.no_data_text')}}):_vm._e()],1)]),_c('b-col',{staticClass:"px-0",attrs:{"lg":"12","md":"12"}},[(_vm.getTotalProfits)?_c('wameed-pagination',{attrs:{"page":_vm.filterData.page,"total-items":_vm.getTotalProfits.totalItems,"per_page":_vm.filterData.per_page},on:{"changePage":_vm.changePage,"changeCurrentPage":_vm.changeCurrentPage},model:{value:(_vm.filterData.page),callback:function ($$v) {_vm.$set(_vm.filterData, "page", $$v)},expression:"filterData.page"}}):_vm._e()],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }